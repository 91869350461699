import React from "react";

export default function Hospitality() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Hospitality">
        <path
          id="Vector"
          d="M391.447 289.893C391.897 292.365 394.052 294.095 396.48 294.095C396.782 294.095 397.092 294.068 397.402 294.013C400.185 293.505 402.027 290.84 401.522 288.058C396.615 261.113 383.647 236.66 364.017 217.35C362.002 215.373 358.762 215.39 356.777 217.41C354.795 219.425 354.82 222.668 356.837 224.65C374.952 242.473 386.922 265.033 391.447 289.893V289.893Z"
          fill="#242424"
        />
        <path
          id="Vector_2"
          d="M303.553 188.767C314.248 193.042 324.288 198.82 333.398 205.94C334.333 206.672 335.443 207.027 336.548 207.027C338.068 207.027 339.575 206.352 340.583 205.06C342.325 202.832 341.93 199.615 339.703 197.875C329.833 190.155 318.948 183.892 307.353 179.257C304.723 178.212 301.745 179.485 300.698 182.112C299.648 184.737 300.928 187.717 303.553 188.767V188.767Z"
          fill="#242424"
        />
        <path
          id="Vector_3"
          d="M68.3902 395.918C58.9927 395.918 51.3452 403.565 51.3452 412.965V443.755C51.3452 453.153 58.9927 460.8 68.3902 460.8H443.755C453.153 460.8 460.8 453.153 460.8 443.755V412.965C460.8 403.565 453.153 395.918 443.755 395.918H305.743V368.775H425.973C445.178 368.775 460.8 353.153 460.8 333.948C460.8 277.993 437.75 223.285 397.56 183.855C363.001 149.949 319.115 129.881 272.406 125.989C285.884 119.781 295.283 106.182 295.283 90.4002C295.283 68.7852 277.693 51.2002 256.073 51.2002C234.45 51.2002 216.86 68.7852 216.86 90.4002C216.86 106.169 226.244 119.759 239.707 125.974C134.767 134.403 51.3452 223.386 51.3452 329.938V333.948C51.3452 353.153 66.9677 368.775 86.1702 368.775H205.628V395.918H68.3902ZM450.56 412.965V443.755C450.56 447.508 447.508 450.56 443.755 450.56H68.3902C64.6377 450.56 61.5852 447.508 61.5852 443.755V412.965C61.5852 409.213 64.6377 406.158 68.3902 406.158H443.755C447.508 406.158 450.56 409.213 450.56 412.965ZM227.1 90.4002C227.1 74.4327 240.098 61.4402 256.073 61.4402C272.048 61.4402 285.043 74.4327 285.043 90.4002C285.043 106.368 272.048 119.358 256.073 119.358C240.098 119.358 227.1 106.368 227.1 90.4002ZM61.5852 333.948V329.938C61.5852 224.765 147.13 137.565 252.28 135.558C253.508 135.533 254.728 135.523 255.95 135.523C306.11 135.523 353.728 155.198 390.388 191.165C428.628 228.683 450.56 280.725 450.56 333.948C450.56 347.505 439.53 358.535 425.973 358.535H86.1702C72.6152 358.535 61.5852 347.505 61.5852 333.948V333.948ZM215.868 368.775H295.503V395.918H215.868V368.775Z"
          fill="#242424"
        />
        <path
          id="Vector_4"
          d="M132.232 90.3998C132.232 93.2273 134.525 95.5198 137.352 95.5198H175.752C178.58 95.5198 180.872 93.2273 180.872 90.3998C180.872 87.5723 178.58 85.2798 175.752 85.2798H137.352C134.525 85.2798 132.232 87.5723 132.232 90.3998Z"
          fill="#242424"
        />
        <path
          id="Vector_5"
          d="M374.792 85.28C377.62 85.28 379.912 82.9875 379.912 80.16C379.912 77.3325 377.62 75.04 374.792 75.04H336.392C333.565 75.04 331.272 77.3325 331.272 80.16C331.272 82.9875 333.565 85.28 336.392 85.28H374.792Z"
          fill="#242424"
        />
        <path
          id="Vector_6"
          d="M176.615 69.8223C176.997 69.9098 177.377 69.9498 177.755 69.9498C180.092 69.9498 182.202 68.3398 182.742 65.9648C183.37 63.2073 181.642 60.4648 178.885 59.8373L141.44 51.3273C138.697 50.6998 135.94 52.4298 135.312 55.1848C134.685 57.9423 136.412 60.6848 139.17 61.3123L176.615 69.8223Z"
          fill="#242424"
        />
        <path
          id="Vector_7"
          d="M329.403 106.508C328.775 109.265 330.503 112.01 333.26 112.635L370.705 121.143C371.088 121.23 371.47 121.27 371.845 121.27C374.183 121.27 376.293 119.66 376.833 117.285C377.46 114.528 375.733 111.783 372.975 111.158L335.53 102.65C332.773 102.028 330.028 103.753 329.403 106.508V106.508Z"
          fill="#242424"
        />
      </g>
    </svg>
  );
}
