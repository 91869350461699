import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import EnergyContext from "../../contexts/energyContext";

import Retail from "../../svgs/energy/Retail";
import Hospitality from "../../svgs/energy/Hospitality";
import Manufacture from "../../svgs/energy/Manufacture";
import Other from "../../svgs/solar/other";

const Next = "/energy/Questions/4";
const Points = [
  {
    question: "What industry are you in?",
    progress: "70%",
    options: [
      {
        answer: "Retail",
        to: Next,
        icon: <Retail />,
      },
      {
        answer: "Hospitality",
        to: Next,
        icon: <Hospitality />,
      },
      {
        answer: "Manufacturing",
        to: Next,
        icon: <Manufacture />,
      },
      {
        answer: "Other",
        to: Next,
        icon: <Other />,
      },
    ],
  },
];

export default function Question3({ title }) {
  const { que3, setQue3 } = useContext(EnergyContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que3 === items.answer}
              onChange={(e) => {
                setQue3(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
