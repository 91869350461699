import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import RadioCircle from "../../svgs/radioCircle";
import EnergyContext from "../../contexts/energyContext";

const Next = "/energy/Questions/3";
const Points = [
  {
    question: "Roughly how much does your business spend on energy per month?",
    progress: "40%",
    options: [
      {
        answer: "Less than $400",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "$400-$1000",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "$1000-$2000",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "$2000-$5000",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "$5000+",
        to: Next,
        icon: <RadioCircle />,
      },
    ],
  },
];

export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(EnergyContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
