import React from "react";

export default function Manufacture() {
  return (
    <svg
      width="640"
      height="640"
      viewBox="0 0 640 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Manufacture" clip-path="url(#clip0)">
        <g id="surface1">
          <path
            id="Vector"
            d="M629.328 373.336H596.613L575.996 73.9453C575.609 68.3399 570.949 63.9961 565.328 64.0039H501.328C495.707 63.9961 491.047 68.3399 490.66 73.9453L470.043 373.336H448V309.336C448 303.449 443.223 298.668 437.336 298.668C434.906 298.664 432.555 299.492 430.664 301.008L341.332 372.473V309.336C341.332 303.449 336.559 298.668 330.668 298.668C328.242 298.664 325.891 299.492 324 301.008L234.668 372.473V309.336C234.668 303.449 229.895 298.668 224.004 298.668C221.578 298.664 219.223 299.492 217.336 301.008L128.004 372.473V309.336C128 303.449 123.223 298.672 117.336 298.672C115.395 298.672 113.5 299.199 111.84 300.195L5.17188 364.195C1.96484 366.125 0 369.59 0 373.336V629.336C0 635.227 4.77734 640 10.668 640H629.328C635.219 640 639.996 635.227 639.996 629.336V384.004C639.996 378.109 635.219 373.336 629.328 373.336ZM511.289 85.3359H555.367L575.234 373.336H491.422L511.289 85.3359ZM362.66 618.668V490.668H426.66V618.668H362.66ZM618.664 618.668H448V480C448 474.113 443.219 469.336 437.328 469.336H351.996C346.105 469.336 341.328 474.113 341.328 480V618.668H21.332V379.375L106.664 328.176V394.668C106.664 400.562 111.438 405.336 117.328 405.344C119.75 405.344 122.105 404.516 124 403L213.332 331.535V394.668C213.332 400.562 218.102 405.336 223.996 405.344C226.418 405.344 228.77 404.516 230.664 403.004L319.996 331.539V394.672C319.996 400.562 324.766 405.344 330.66 405.344C333.082 405.344 335.434 404.516 337.328 403.004L426.66 331.539V384.004C426.66 389.898 431.438 394.672 437.328 394.672H618.656V618.668H618.664Z"
            fill="#242424"
          />
          <path
            id="Vector_2"
            d="M362.59 21.3399C366.469 23.418 369.973 26.1289 372.961 29.3594C378.941 36.6524 387.457 41.4024 396.797 42.6719C406.637 41.418 415.621 36.461 421.93 28.8047C424.629 25.9727 427.648 23.4688 430.934 21.3399C433.129 21.4375 438.879 26.8203 441.973 29.711C447.727 36.832 455.992 41.4688 465.063 42.6719C475.539 41.3438 485.113 36.0899 491.859 27.9727C494.473 25.5196 498.43 21.7969 499.195 21.3399C504.922 21.3399 506.234 23.2383 509.758 28.3164C514.387 37.0625 523.434 42.5742 533.332 42.6719C539.219 42.6719 543.996 37.8946 543.996 32.0078C543.996 26.1133 539.219 21.3399 533.332 21.3399C531.195 21.3399 530.781 21.1914 527.316 16.1875C521.652 6.01174 510.836 -0.21092 499.195 0.00392439C490.566 1.20314 482.715 5.64064 477.246 12.4219C473.703 15.7617 467.766 21.3399 465.512 21.3906C462.207 19.3906 459.191 16.9531 456.527 14.1524C450.113 6.33986 440.957 1.28127 430.93 0.00392439C421.684 1.26564 413.262 5.97658 407.344 13.1875C404.352 16.3985 400.895 19.1445 397.094 21.3399C393.539 19.3008 390.305 16.7539 387.496 13.7656C381.262 6.19533 372.387 1.28127 362.66 0.00392439C356.773 -0.0156069 351.977 4.74221 351.957 10.6367C351.938 16.5235 356.699 21.3203 362.59 21.3399Z"
            fill="#242424"
          />
          <path
            id="Vector_3"
            d="M117.336 490.668H74.6641C68.7734 490.668 64 495.445 64 501.332V565.332C64 571.227 68.7734 576 74.6641 576H117.336C123.223 576 128 571.227 128 565.332V501.332C128 495.445 123.223 490.668 117.336 490.668ZM106.664 554.668H85.332V512.004H106.664V554.668Z"
            fill="#242424"
          />
          <path
            id="Vector_4"
            d="M202.668 490.668H160C154.105 490.668 149.332 495.445 149.332 501.332V565.332C149.332 571.227 154.105 576 160 576H202.668C208.555 576 213.332 571.227 213.332 565.332V501.332C213.332 495.445 208.555 490.668 202.668 490.668ZM191.996 554.668H170.664V512.004H191.996V554.668Z"
            fill="#242424"
          />
          <path
            id="Vector_5"
            d="M288 490.668H245.332C239.438 490.668 234.664 495.445 234.664 501.332V565.332C234.664 571.227 239.438 576 245.332 576H288C293.887 576 298.664 571.227 298.664 565.332V501.332C298.664 495.445 293.887 490.668 288 490.668ZM277.328 554.668H255.996V512.004H277.328V554.668Z"
            fill="#242424"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="640" height="640" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
